import {dataTestId} from '@hconnect/uikit'
import {
  CardBox,
  customThemeConstants,
  IconWithLabelButton,
  useBreakPoints
} from '@hconnect/uikit/src/lib2'
import {SaveAlt as SaveAltIcon, Check as CheckIcon} from '@mui/icons-material'
import {Box, Typography, Stack, styled, Button, TextField, MenuItem} from '@mui/material'
import moment from 'moment'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'

import {CalendarRange} from '../../enums'
import {CalendarDatePicker} from '../common/CalendarDatePicker'

import {CategoriesTree} from './CategoriesTree'

const productionFilterOptions = ['AC', 'PL', 'FC']
const dataTypesFilterOptions = ['all', 'missing', 'autoFilled', 'manual']
const contextMonthsFilterOptions = [
  'lastYear',
  'yearToDate',
  'last3Months',
  'yearToMonth',
  'lastMonth',
  'currentMonth'
]
const CustomButton = styled(Button)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minWidth: '20px',
  marginX: 0,
  color: 'white',
  overflow: 'hidden',
  border: 'none'
})

const Select = styled(TextField)(({theme}) => ({
  width: 200,
  height: 52,
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    borderColor: 'rgba(255, 255, 255, 0.25)',
    height: '100%',
    padding: '0 8px', // Adjust padding to fit within 50px height
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent'
    }
  },
  '& .MuiInputLabel-root': {
    color: customThemeConstants().palette.mediumInputBorder
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.common.white
  },
  '& .MuiFilledInput-underline:before': {
    borderBottomColor: 'rgba(255, 255, 255, 0.25)'
  },
  '& .MuiFilledInput-underline:hover:before': {
    borderBottomColor: 'rgba(255, 255, 255, 0.25)'
  },
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: 'rgba(255, 255, 255, 0.25)'
  },
  '& .MuiSelect-icon': {
    color: theme.palette.common.white
  }
}))

export const MonthlyEntry = () => {
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const [searchParams, setSearchParams] = useSearchParams()

  const [selectedProductionOption, setSelectedProductionOption] = useState<number | undefined>(
    productionFilterOptions.findIndex((x) => x === 'AC')
  )
  const [selectedDataTypeFilterOption, setSelectedDataTypeFilterOption] = useState<
    string | undefined
  >()
  const [selectedcontextMonthsFilterOptions, setSelectedContextMonthsFilterOptions] = useState<
    string | undefined
  >()

  const selectedDate = useMemo(() => {
    const date = searchParams.get('date')
    const defaultDate = moment().subtract(1, 'days').toISOString()
    if (!date) {
      setSearchParams((params) => {
        params.set('date', defaultDate)
        return params
      })
    }
    return date ?? defaultDate
  }, [searchParams, setSearchParams])

  const {t} = useTranslation()

  return (
    <Stack gap={2} alignItems="center" width="100%">
      <Box
        width={isMobile ? '100%' : '95%'}
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        gap={2}
        {...dataTestId('monthly-entry-container')}
      >
        <Typography variant="h1" sx={{width: isMobile ? '100%' : '30%'}}>
          {t('janusConfig.monthlyEntry.monthlyEntry')}
        </Typography>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          justifyContent="flex-end"
          gap={1}
        >
          <Box
            sx={{
              backgroundColor: '#01356A',
              display: 'flex',
              overflowX: 'auto',
              borderRadius: 1,
              flexDirection: 'row',
              ...(isMobile && {width: '100%'})
            }}
            {...dataTestId('production-filter')}
          >
            {productionFilterOptions.map((option, index) => (
              <CustomButton
                variant="text"
                {...dataTestId(`production-filter-${option}-button`)}
                onClick={() => setSelectedProductionOption(index)}
                key={`production-option-${option}`}
                sx={{
                  margin: 0.3,
                  backgroundColor: (theme) =>
                    index === selectedProductionOption ? theme.palette.primary.main : 'transparent',
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.primary.main
                  },
                  '&.Mui-disabled': {
                    color: 'rgba(255,255,255,0.25)'
                  },
                  color: 'white'
                }}
              >
                <Typography variant="body2">{option}</Typography>
              </CustomButton>
            ))}
          </Box>
          <Box display="flex" flexDirection="row" alignItems="flex-end" gap={1}>
            <CalendarDatePicker
              selectedDate={selectedDate}
              setSelectedDate={(date) => {
                setSearchParams((params) => {
                  params.set('date', date || '')
                  return params
                })
              }}
              type={CalendarRange.Monthly}
              fullWidth={false}
            />
            {isMobile ? (
              <Button
                {...dataTestId('mobile-save-button')}
                sx={{color: 'white', height: 52, borderColor: 'rgba(255, 255, 255, 0.13)'}}
                variant="outlined"
              >
                <SaveAltIcon />
              </Button>
            ) : (
              <Box display="flex" flexDirection="row" gap={1} alignItems="flex-end">
                <Select
                  variant="filled"
                  label={t('janusConfig.monthlyEntry.contextMonths')}
                  {...dataTestId('context-months-filter')}
                  value={selectedcontextMonthsFilterOptions}
                  onChange={(event) => setSelectedContextMonthsFilterOptions(event.target.value)}
                  SelectProps={{
                    renderValue: (value: unknown) => t(`janusConfig.monthlyEntry.${value}`)
                  }}
                  select
                >
                  {contextMonthsFilterOptions.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      {...dataTestId(`context-months-${option}-option`)}
                    >
                      {t(`janusConfig.monthlyEntry.${option}`)}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  variant="filled"
                  {...dataTestId('data-types-filter')}
                  label={t('janusConfig.monthlyEntry.dataType')}
                  value={selectedDataTypeFilterOption}
                  onChange={(event) => setSelectedDataTypeFilterOption(event.target.value)}
                  SelectProps={{
                    renderValue: (value: unknown) => t(`janusConfig.monthlyEntry.${value}`)
                  }}
                  select
                >
                  {dataTypesFilterOptions.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      {...dataTestId(`data-types-${option}-option`)}
                    >
                      {t(`janusConfig.monthlyEntry.${option}`)}
                    </MenuItem>
                  ))}
                </Select>
                <IconWithLabelButton
                  sx={(theme) => ({
                    minWidth: '100px',
                    border: 1,
                    borderColor: customThemeConstants().palette.mediumInputBorder,
                    color: theme.palette.common.white,
                    padding: 2,
                    height: '52px'
                  })}
                  {...dataTestId('export-button')}
                  startIcon={<SaveAltIcon />}
                  label={t('janusConfig.monthlyEntry.export')}
                  onClick={() => {}}
                />
                <Button
                  sx={{
                    minWidth: '100px',
                    height: '52px',
                    '&.Mui-disabled': {
                      backgroundColor: 'rgba(1, 106, 212, 0.4)',
                      color: 'white',
                      '.MuiButton-startIcon': {
                        color: 'red !important'
                      },
                      '.MuiButton-iconSizeMedium': {
                        color: 'red !important'
                      }
                    }
                  }}
                  color={'primary'}
                  startIcon={<CheckIcon />}
                  variant="contained"
                  {...dataTestId('monthly-entry-save-button')}
                  onClick={() => {}}
                >
                  {t('janusConfig.monthlyEntry.save')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        justifyItems="space-between"
        width="100%"
        height="540px"
      >
        <CategoriesTree selectedDate={selectedDate} width={isMobile ? '100%' : '25%'} />
        {!isMobile && <CardBox width="75%">Table placeholder</CardBox>}
      </Box>
    </Stack>
  )
}
