import MockAdapter from 'axios-mock-adapter'
import moment from 'moment'

import {EditKpiStatus, EventType} from '../../modules/janus/enums'
import {
  Comment,
  DailyKpiInfo,
  EditKpiResponse,
  EditedKpi,
  Event,
  FinalValue
} from '../../modules/janus/types'

import {numberRegEx, saveScenario, sleepResponse, uuidRegEx} from './utils'

import {mockStore} from '@settings/__mock__/mockStore'

const regexDate = '\\d{4}-\\d{2}-\\d{2}(T\\d{2}:\\d{2}:\\d{2}(\\.\\d{3})?Z?)?'

export const enableJanusEndpoints = (mock: MockAdapter) => {
  mock
    .onGet(new RegExp(`^/kpi-result/janus-kpi/${numberRegEx}/daily/${uuidRegEx}/events$`))
    .reply(() => {
      const {
        burglengenfeld: {kpiDailyEvents}
      } = mockStore.scenario()

      return sleepResponse([200, kpiDailyEvents])
    })

  mock
    .onGet(new RegExp(`^/kpi-result/janus-kpi/${numberRegEx}/daily/${regexDate}/comments$`))
    .reply(() => {
      const {
        burglengenfeld: {kpiDailyComments}
      } = mockStore.scenario()

      return sleepResponse([200, kpiDailyComments])
    })
  mock
    .onGet(new RegExp(`^/janus-monthly-result/janus-kpi/${numberRegEx}/tree/${regexDate}$`))
    .reply(() => {
      const {
        burglengenfeld: {monthlyEntriesCategories}
      } = mockStore.scenario()

      return sleepResponse([200, monthlyEntriesCategories])
    })

  mock
    .onGet(new RegExp(`^/kpi-result/janus-kpi/${numberRegEx}/daily/${regexDate}/calendar-info/1$`))
    .reply(() => {
      const {
        burglengenfeld: {kpiMonthlyCalendar}
      } = mockStore.scenario()

      return sleepResponse([200, kpiMonthlyCalendar])
    })

  mock
    .onGet(new RegExp(`^/kpi-result/janus-kpi/${numberRegEx}/cumulated/${regexDate}`))
    .reply(() => {
      const {
        burglengenfeld: {kpiCumulatedDailyEntries}
      } = mockStore.scenario()

      return sleepResponse([200, kpiCumulatedDailyEntries])
    })

  mock
    .onGet(new RegExp(`^/kpi-result/janus-kpi/${numberRegEx}/daily/${regexDate}/calendar-info/2$`))
    .reply(() => {
      const {
        burglengenfeld: {kpiWeeklyCalendar}
      } = mockStore.scenario()

      return sleepResponse([200, kpiWeeklyCalendar])
    })

  mock
    .onPost(new RegExp(`^/kpi-result/janus-kpi/${numberRegEx}/daily/${uuidRegEx}/comments$`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const {value} = JSON.parse(config.data as string) as Comment

      const newComment: Comment = {
        commentDate: moment.utc().toISOString(),
        id: Date.now().toString(),
        kpiName: 'kpi name for new comment',
        kpiId: '1',
        userName: 'User Name',
        value,
        commentParentId: null
      }
      const newEvent: Event = {
        eventDate: moment.utc().toISOString(),
        eventType: EventType.Comment,
        id: Date.now().toString(),
        kpiName: 'kpi name for new comment',
        version: '1',
        userName: 'User Name',
        value
      }

      scenario.burglengenfeld.kpiDailyComments.janusComments = [
        ...scenario.burglengenfeld.kpiDailyComments.janusComments,
        newComment
      ]
      scenario.burglengenfeld.kpiDailyEvents.events = [
        ...scenario.burglengenfeld.kpiDailyEvents.events,
        newEvent
      ]

      saveScenario(scenario)
      return sleepResponse([201, newComment])
    })

  mock
    .onPost(new RegExp(`^/kpi-result/janus-kpi/${numberRegEx}/daily/${regexDate}$`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const newDailyKpis = JSON.parse(config.data as string) as DailyKpiInfo[]

      scenario.burglengenfeld.kpiDailyEntries = newDailyKpis

      const janusDailyKpis: EditedKpi[] = newDailyKpis.map((entry) => {
        if (entry.value?.includes('e')) {
          return {id: entry.id, status: EditKpiStatus.ValidationFail}
        }
        return {id: entry.id, status: EditKpiStatus.Success}
      })

      const response: EditKpiResponse = {janusDailyKpis}

      saveScenario(scenario)
      return sleepResponse([201, response])
    })

  mock
    .onPost(new RegExp(`^/kpi-result/janus-kpi/${numberRegEx}/cumulated/${regexDate}$`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const newDailyKpis = JSON.parse(config.data as string) as FinalValue[]

      scenario.burglengenfeld.kpiCumulatedDailyEntries.finalValues = newDailyKpis

      saveScenario(scenario)
      return sleepResponse([201, {}])
    })

  mock
    .onGet(new RegExp(`^/kpi-result/janus-kpi/${numberRegEx}/daily/${regexDate}(\\?.*)?$`))
    .reply(() => {
      const {
        burglengenfeld: {kpiDailyEntries}
      } = mockStore.scenario()

      return sleepResponse([200, kpiDailyEntries])
    })

  return mock
}
